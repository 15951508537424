import ISkillEffect from './ISkillEffect';
import ISkillEffectValue from './ISkillEffectValue';
import ITypedMap from '../util/ITypedMap';
import { filters } from '@/filters/Filters';
import SkillProvider from '@/api/SkillProvider';
import { CreateElement, VNode } from 'vue';
import TableProvider from '@/api/TableProvider';
import ISkill from './ISkill';
import { TokenParser } from '@/util/TokenParser';
import type { ITableRow, UiStringResolvedTableRow } from '@vincentzhang96/dv-dnt-table-interfaces';
import { ISkillBubbleDefineTableRow } from '@vincentzhang96/dv-dnt-table-interfaces/src/duck';

export default interface IStateBlow {
    name: string;
    describe?(effect: ISkillEffect, value?: ISkillEffectValue): string|IDescription|Promise<IDescription|null>|null;
    describeTsx?(h: CreateElement, effect: ISkillEffect, value?: ISkillEffectValue): Promise<VNode|null>;
}

export interface IDescription {
    text: string;
    appendDuration?: boolean;
}

async function renderChild(h: CreateElement, stateEffectId: number, stateEffectParameters: string, duration: number, effect: ISkillEffect) {
    const seidProcessor = Blows[stateEffectId];
    if (seidProcessor) {
        let res = null;
        if (seidProcessor.describeTsx) {
            res = await seidProcessor.describeTsx(h, effect, {
                value: stateEffectParameters,
                duration: duration,
                index: -1,
            });
        } else if (seidProcessor.describe) {
            res = await seidProcessor.describe(effect, {
                value: stateEffectParameters,
                duration: duration,
                index: -1,
            });
        } else {
            return (
                <div class="child-effect">
                    <div class="effect-id">
                        { stateEffectId }
                    </div>
                    <div class="effect-name">
                        { seidProcessor.name }
                    </div>
                    <div class="description">
                    Parameter { stateEffectParameters } <span class="raw">{ stateEffectParameters } apply to { effect.effectApplyType }</span>
                    </div>
                </div>
            );
        }
        
        if (res) {
            return (
                <div class="child-effect">
                    <div class="effect-id">
                        { stateEffectId }
                    </div>
                    <div class="effect-name">
                        { seidProcessor.name }
                    </div>
                    <div class="description">
                        { res } <span class="raw">{ stateEffectParameters } apply to { effect.effectApplyType }</span>
                    </div>
                </div>
            );
        }
    }
    return (
        <div class="child-effect">
            <div class="effect-id">
                { stateEffectId }
            </div>
            <div class="effect-name">
                Effect { stateEffectId }
            </div>
            <div class="description">
                Parameter { stateEffectParameters } <span class="raw">{ stateEffectParameters } apply to { effect.effectApplyType }</span>
            </div>
        </div>
    );
}

function statIncreasePercent(statName: string, mod: number = 0): IStateBlow {
    return {
        name: `${statName} mod`,
        async describe(effect, value): Promise<IDescription|null> {
            if (value) {
                const split = value.value.split(";");
                let amount = Number(split[0]);
                let appliesTo = split.length > 0 ? split.slice(1).join(';') : null;
                if (!isNaN(amount)) {
                    amount += mod;
                    let incr = "increased";
                    if (amount < 0) {
                        incr = "decreased";
                        amount *= -1;
                    }

                    if (appliesTo) {
                        const applySplit = appliesTo.split(/;|,/);
                        const promises = (applySplit.map((v) => Number(v))
                            .filter((v) => !isNaN(v) && v > 1)
                            .map(async (v) => {
                                try {
                                    return await SkillProvider.getSkill(v);
                                } catch (e) {
                                    return {
                                        name: {
                                            id: -1,
                                            message: `Skill ${v}`
                                        },
                                        id: v,
                                    };
                                }
                            }));
                        const skills = await Promise.all(promises);
                        if (skills.length > 0) {
                            appliesTo = "during " + skills
                                .map((v) => v.name.message)
                                .join(", ");
                        } else {
                            appliesTo = null;
                        }
                    }

                    return {
                        text: `${statName} ${appliesTo ? appliesTo : ""} ${incr} by ${filters.percent(amount)}%`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        },
        async describeTsx(h, effect, value) {
            if (value) {
                const split = value.value.split(";");
                let amount = Number(split[0]);
                let appliesTo = split.length > 0 ? split.slice(1).join(';') : null;
                if (!isNaN(amount)) {
                    amount += mod;
                    let incr = "increased";
                    if (amount < 0) {
                        incr = "decreased";
                        amount *= -1;
                    }

                    let applies = null;
                    if (appliesTo) {
                        const skillIds = appliesTo.split(/;|,/).map((v) => Number(v)).filter((v) => !isNaN(v));
                        
                        const skills = await skillListTsxIds(h, skillIds);
                        
                        applies = <span>during { skills }</span>
                    }
                    
                    return (
                        <span>{ statName } { applies } { incr } by { filters.percent(amount) }% { durationTsx(h, value.duration) }</span>
                    )
                }
            }
            
            return null;
        }
    };
}

function statIncrease(statName: string, mod: number = 0): IStateBlow {
    return {
        name: `${statName} mod`,
        async describe(effect, value): Promise<IDescription|null> {
            if (value) {
                const split = value.value.split(";");
                let amount = Number(split[0]);
                let appliesTo = split[1] || null;
                if (!isNaN(amount)) {
                    amount += mod;
                    let incr = "increased";
                    if (amount < 0) {
                        incr = "decreased";
                        amount *= -1;
                    }

                    if (appliesTo) {
                        const applySplit = appliesTo.split(",");
                        const promises = (applySplit.map((v) => Number(v))
                            .filter((v) => !isNaN(v) && v > 1)
                            .map(async (v) => {
                                try {
                                    return await SkillProvider.getSkill(v);
                                } catch (e) {
                                    return {
                                        name: {
                                            id: -1,
                                            message: `Skill ${v}`
                                        },
                                        id: v,
                                    };
                                }
                            }));
                        const skills = await Promise.all(promises);
                        if (skills.length > 0) {
                            appliesTo = "during " + skills
                                .map((v) => v.name.message)
                                .join(", ");
                        } else {
                            appliesTo = null;
                        }
                    }

                    return {
                        text: `${statName} ${appliesTo ? appliesTo : ""} ${incr} by ${amount}`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        },
    };
}

function getBlow(id: number): IStateBlow|null {
    return Blows[id] || null;
}

function durationTsx(h: CreateElement, duration: number): VNode|null {
    if (duration) {
        return (
            <span>for {filters.milliseconds(duration)} seconds</span>
        );
    }
    
    return null;
}

function skillTsx(h: CreateElement, skill: ISkill): VNode {
    const url = `/skills/${skill.id}`;
    return (
        <a href={url}>{`${skill.name.message} (${skill.id})`}</a>
    );
}

function skillListTsx(h: CreateElement, skills: ISkill[]): VNode {
    const sk = skills
        .map((av) => {
            return skillTsx(h, av);
        });
        
    return <ul class="inline-list">{sk}</ul>
}

async function skillListTsxIds(h: CreateElement, skillIds: number[]): Promise<VNode> {
    const skills = await Promise.all(skillIds
        .filter((v) => v > 0 && !isNaN(v))
        .map(async (v) => {
            return await getSkillSafe(v);
        }));
    
    return skillListTsx(h, skills);
}

async function getSkillSafe(skillId: number): Promise<ISkill> {
    try {
        return await SkillProvider.getSkill(skillId);
    } catch (e) {
        return {
            name: {
                id: -1,
                message: `Skill ${skillId}`
            },
            id: skillId,
        } as ISkill;
    }
}

function safeReduce<T, R>(arr: T[], reduceFunc: (pv: R, v: T) => R): R[] {
    if (arr.length) {
        return arr.reduce(reduceFunc as any) as any;
    }
    
    return [];
}

function vnodeJoin(v: VNode[], joiner: string): VNode[] {
    return safeReduce(v, (prev, next) => [prev, joiner, next] as any);
}

export const Blows: ITypedMap<IStateBlow> = {
    1: {
        name: "Skill physical damage",
        describe(effect, value) {
            if (value) {
                let amount = Number(value.value);
                if (!isNaN(amount)) {
                    return {
                        text: `Skill gains ${filters.thousands(amount)} physical damage`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    2: {
        name: "Skill physical scaling",
        describe(effect, value) {
            if (value) {
                let amount = Number(value.value);
                if (!isNaN(amount)) {
                    amount += 1;
                    return {
                        text: `Skill physical damage multiplier becomes ${filters.percent(amount, 1)}%`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    3: statIncrease("Physical defense"),
    4: statIncreasePercent("Physical defense"),
    5: statIncrease("STR"),
    6: statIncrease("AGI"),
    7: statIncrease("INT"),
    8: statIncrease("VIT"),
    9: statIncrease("HP"),
    10: statIncrease("MP"),
    11: {
        name: "HP over time",
    },
    12: {
        name: "Percent HP over time",
        describe(effect, value) {
            if (value) {
                let amount = Number(value.value);
                if (!isNaN(amount)) {
                    return {
                        text: `${amount < 0 ? "Drains" : "Restores"} ${filters.percent((amount < 0 ? -1 : 1) * amount, 1)}% HP over time`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    13: {
        name: "MP over time",
    },
    14: {
        name: "Percent MP over time",
        describe(effect, value) {
            if (value) {
                let amount = Number(value.value);
                if (!isNaN(amount)) {
                    return {
                        text: `${amount < 0 ? "Drains" : "Restores"} ${filters.percent((amount < 0 ? -1 : 1) * amount, 1)}% MP`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    15: {
        name: "HP adjustment",
        describe(effect, value) {
            if (value) {
                const v = value.value.split(";");
                let amount = Number(v[0]);
                if (!isNaN(amount)) {
                    return {
                        text: `${amount < 0 ? "Depletes" : "Heals"} ${filters.thousands((amount < 0 ? -1 : 1) * amount)} HP`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    16: {
        name: "Percent HP adjustment",
        describe(effect, value) {
            if (value) {
                const v = value.value.split(";");
                let amount = Number(v[0]);
                if (!isNaN(amount)) {
                    return {
                        text: `${amount < 0 ? "Depletes" : "Heals"} ${filters.percent((amount < 0 ? -1 : 1) * amount, 1)}% HP`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    17: {
        name: "MP adjustment",
        describe(effect, value) {
            if (value) {
                const v = value.value.split(";");
                let amount = Number(v[0]);
                if (!isNaN(amount)) {
                    return {
                        text: `${amount < 0 ? "Depletes" : "Heals"} ${filters.thousands((amount < 0 ? -1 : 1) * amount)} MP`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    18: {
        name: "Percent MP adjustment",
        describe(effect, value) {
            if (value) {
                const v = value.value.split(";");
                let amount = Number(v[0]);
                if (!isNaN(amount)) {
                    return {
                        text: `${amount < 0 ? "Depletes" : "Heals"} ${filters.percent((amount < 0 ? -1 : 1) * amount, 1)}% MP`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    19: statIncrease("Para"),
    20: statIncrease("Para resist"),
    21: statIncrease("Critical"),
    22: statIncrease("Critical resist"),
    23: statIncrease("Stun"),
    24: statIncrease("Stun resist"),
    25: statIncreasePercent("Animation speed"),
    26: statIncrease("Down delay"),

    28: {
        name: "Skill magic damage",
        describe(effect, value) {
            if (value) {
                let amount = Number(value.value);
                if (!isNaN(amount)) {
                    return {
                        text: `Skill gains ${filters.thousands(amount)} magic damage`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    29: {
        name: "Skill magic scaling",
        describe(effect, value) {
            if (value) {
                let amount = Number(value.value);
                if (!isNaN(amount)) {
                    amount += 1;
                    return {
                        text: `Skill magic damage multiplier becomes ${filters.percent(amount, 1)}%`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    }, 
    30: statIncreasePercent("Block chance"),
    31: statIncreasePercent("Parrying chance"),
    32: statIncreasePercent("Fire attack"),
    33: statIncreasePercent("Ice attack"),
    34: statIncreasePercent("Light attack"),
    35: statIncreasePercent("Dark attack"),
    36: statIncreasePercent("Fire resist"),
    37: statIncreasePercent("Ice resist"),
    38: statIncreasePercent("Light resist"),
    39: statIncreasePercent("Dark resist"),

    41: {
        name: "Ice Stack",
        async describeTsx(h, effect, value) {
            if (value) {
                const tp = new TokenParser(value.value);
                const freezeRate = Number(tp.takeNext(';'));
                const durability = Number(tp.takeNext(';'));
                const hpFlatDamage = tp.hasNext(';') ? Number(tp.takeNext(';')) : 0;
                
                return (
                    <span>
                        Freeze targets at a {filters.percent(freezeRate)}% chance,
                        with a durability of {filters.thousands(durability)} { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        }
    },
    42: {
        name: "Burn",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                let rate = Number(split[0]) * 100;
                let damagePercent = Number(split[1]);
                let damage = Number(split[2]);

                const damageStr = (isNaN(damagePercent) || damagePercent == 0) ? filters.thousands(damage) : `${filters.percent(damagePercent)}%`;

                return {
                    text: `${rate}% chance to afflict ${damageStr} fire damage every 2s`,
                    appendDuration: true,
                };
            }

            return null;
        },
        async describeTsx(h, effect, value) {
            if (value) {
                const tp = new TokenParser(value.value);
                const rate = Number(tp.takeNext(';'));
                const hpPercentDamage = tp.hasNext(';') ? Number(tp.takeNext(';')) : 0;
                const additionalDamage = tp.hasNext(';') ? Number(tp.takeNext(';')) : 0;
                
                return (
                    <span>
                        Burn targets at a {filters.percent(rate)}% chance, dealing {filters.percent(hpPercentDamage)}% HP damage and {filters.thousands(additionalDamage)} additional damage { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },
    43: {
        name: "Electrocution",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                let chance = Number(split[0]) * 100;
                let damage = split[1] && Number(split[1]);

                const damageStr = damage ? `Light Resist ${damage < 0 ? 'reduced' : 'increased'} by ${damage * 100}%` : '';

                return {
                    text: `${chance}% chance to electrocute${damageStr ? ', ' + damageStr : ''}`,
                    appendDuration: true,
                };
            }

            return null;
        }
    },
    44: {
        name: "Dark Burn",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                let chance = Number(split[0]) * 100;
                let damagePercent = Number(split[1]);
                let damage = Number(split[2]);

                const damageStr = (isNaN(damagePercent) || damagePercent == 0) ? filters.thousands(damage) : `${filters.percent(damagePercent)}%`;

                return {
                    text: `${chance}% chance to afflict ${damageStr} dark damage every 2s`,
                    appendDuration: true,
                };
            }

            return null;
        }
    },
    45: {
        name: "Sleep",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let chance = Number(v) * 100;

                return {
                    text: `${chance}% chance to afflict sleep`,
                    appendDuration: true,
                };
            }

            return null;
        }
    },

    55: {
        name: "Health barrier",
        async describeTsx(h, effect, value) {
            if (value) {
                const tp = new TokenParser(value.value);
                const tokens = tp.takeAll(';');
                let stateValue = 0;
                let durability = 0;
                let durabilityByActorHp = 0;
                let applySuperArmor = false;
                let applySuperArmorValue = 0;
                let applyToSkillUser = true;
                
                if (tokens.length >= 5) {
                    applyToSkillUser = Number(tokens[4]) === 1;
                }
                
                const selfInfo = applyToSkillUser ? null : (<span>, excluding self</span>)
                
                if (tokens.length === 3) {
                    stateValue = Number(tokens[0]);
                    durability = Math.floor(stateValue);
                    applySuperArmor = Number(tokens[1]) === 1;
                    applySuperArmorValue = Number(tokens[2]);
                    
                    const saInfo = applySuperArmor ? (
                        <span>granting {filters.thousands(applySuperArmorValue)} super armor</span>
                    ) : null;
                    
                    return (
                        <span>
                            Apply a health barrier with {filters.thousands(durability)} HP {saInfo} {selfInfo} { durationTsx(h, value.duration) }
                        </span>
                    );
                } else if (tokens.length >= 4) {
                    durabilityByActorHp = Number(tokens[0]);
                    stateValue = Number(tokens[1]);
                    applySuperArmor = Number(tokens[2]) === 1;
                    applySuperArmorValue = Number(tokens[3]);
                    
                    const saInfo = applySuperArmor ? (
                        <span>granting {filters.thousands(applySuperArmorValue)} super armor</span>
                    ) : null;
                    
                    return (
                        <span>
                            Apply a health barrier with {filters.thousands(durabilityByActorHp)}% of target's HP {saInfo} {selfInfo} { durationTsx(h, value.duration) }
                        </span>
                    );
                } else {
                    stateValue = Number(tokens[0]);
                    durability = Math.floor(stateValue);
                    
                    return (
                        <span>
                            Apply a health barrier with {filters.thousands(durability)} HP {selfInfo} { durationTsx(h, value.duration) }
                        </span>
                    );
                }
            }
            
            return null;
        },
    },

    57: {
        name: "Revive",
    },

    58: statIncreasePercent("Max HP"),
    59: statIncreasePercent("Max MP"),
    60: {
        name: "Chain lightning effect",
        describe(effect, value) {
            if (value) {
                const split = value.value.split(';');
                if (split.length === 3) {
                    return `Bounce up to ${split[1]} times between enemies within ${split[0]} range at a ${filters.percent(split[2])}% rate`;
                }
            }

            return null;
        }
    },
    61: {
        name: "Super armor increase",
        describe(effect, value) {
            if (value) {
                let param = Number(value.value);
                if (!isNaN(param)) {
                    return {
                        text: `Super armor increased by ${filters.thousands(param)}`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    62: {
        name: "Apply layered action",
        describe(effect, value) {
            if (value) {
                return {
                    text: `Apply layered action effect ${value.value}`,
                    appendDuration: true,
                }
            }

            return null;
        },
    },
    63: {
        name: "Petrify",
    },
    64: statIncreasePercent("Super armor"),
    65: statIncrease("Range"),
    66: {
        name: "Area of Effect Threat",
        async describeTsx(h, effect, value) {
            if (value) {
                const tp = new TokenParser(value.value);
                const threat = Number(tp.takeNext(';'));
                const range = Number(tp.takeNext(';'));
                const angle = Number(tp.takeNext(';'));
                
                return (
                    <span>
                        Increase your threat by {filters.thousands(threat)} to enemies within {filters.thousands(range)} units in a {angle} degree sweep
                    </span>
                );
            }
            
            return null;
        },
    },
    67: {
        name: "Unable to use skills"
    },
    68: {
        name: "MP refund"
    },
    69: {
        name: "Cleanse",
        async describeTsx(h, effect, value) {
            if (value) {
                const tp = new TokenParser(value.value);
                const count = Number(tp.takeNext(';'));
                const skipTeamCheck = tp.hasNext(';') ? Number(tp.takeNext(';')) > 0 : false;
                const skipDissolvableCheck = tp.hasNext(';') ? Number(tp.takeNext(';')) > 0 : false;
                
                return (
                    <span>
                        Cleanse {filters.thousands(count)} negative effects {skipTeamCheck ? 'regardless of team' : ''} {skipDissolvableCheck ? 'even if uncurable' : ''}
                    </span>
                );
            }
            
            return null;
        },
    },
    70: {
        name: "Cannot move",
    },
    71: {
        name: "Cannot act",
    },
    72: {
        name: "Cooldown",
    },
    73: {
        name: "Invisibility",
    },
    74: {
        name: "Maximum damage",
    },
    75: statIncrease("Movement speed"),
    76: statIncreasePercent("Movement speed"),
    77: {
        name: "Effect immunity",
    },
    78: {
        name: "Silenced",
        describe(effect, value) {
            return {
                text: "Unable to use skills",
                appendDuration: true,
            };
        },
    },
    79: {
        name: 'Force break SA',
    },
    82: {
        name: "[DEPRECATED] Percent FD",
    },
    83: {
        name: "Buff wipe",
        describe(effect, value) {
            return null;
        }
    },
    84: {
        name: "Forced action",
    },
    86: {
        name: "Team switch",
        describe: (e, v) => ({
            text: "Lovesick",
            appendDuration: true,
        }),
    },
    87: statIncreasePercent("STR"),
    88: statIncreasePercent("AGI"),
    89: statIncreasePercent("INT"),
    90: statIncreasePercent("VIT"),

    93: statIncrease("Magic defense"),
    94: statIncreasePercent("Magic defense"),
    96: {
        name: "Skill Cooldown",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                let ratio = Number(split[0]);
                if (!isNaN(ratio)) {
                    ratio -= 1;

                    return {
                        text: ratio < 0 ? `Cooldowns reduced by ${filters.percent(-ratio)}%` : `Cooldowns increased by ${filters.percent(ratio)}%`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },

    99: {
        name: "Invincibility",
        describe(effect: ISkillEffect, value?: ISkillEffectValue) {
            if (value && value.duration == 0) {
                return null;
            }

            return {
                text: "Invincible",
                appendDuration: true,
            };
        },
    },

    100: {
        name: "Null/placeholder effect",
        describe(effect, value) {
            if (value) {
                return {
                    text: `Dummy parameters ${value.value}`,
                    appendDuration: true,
                }
            }

            return null;
        }
    },
    
    111: {
        name: "Reflect physical damage",
        describe(effect, value) {
            if (value) {
                const percent = Number(value.value);
                if (!isNaN(percent)) {
                    return `Reflect ${filters.percent(percent)}% of incoming physical damage`;
                }
            }

            return null;
        }
    },
    112: {
        name: "Reflect physical damage to projectile owner",
        describe(effect, value) {
            if (value) {
                const percent = Number(value.value);
                if (!isNaN(percent)) {
                    return `Reflect ${filters.percent(percent)}% of incoming projectile physical damage back to caster`;
                }
            }

            return null;
        }
    },
    113: {
        name: "Reflect magic damage",
        describe(effect, value) {
            if (value) {
                const percent = Number(value.value);
                if (!isNaN(percent)) {
                    return `Reflect ${filters.percent(percent)}% of incoming magic damage`;
                }
            }

            return null;
        }
    },
    114: {
        name: "Reflect magic damage to projectile owner",
        describe(effect, value) {
            if (value) {
                const percent = Number(value.value);
                if (!isNaN(percent)) {
                    return `Reflect ${filters.percent(percent)}% of incoming projectile magic damage back to caster`;
                }
            }

            return null;
        }
    },
    115: {
        name: "Poison detonate",
    },
    116: {
        name: "Burn detonate",
    },
    117: {
        name: "Frozen detonate",
    },
    118: {
        name: "Electric detonate",
    },

    121: {
        name: "Replace Stand action",
    },

    123: statIncreasePercent("Paralyze"),
    124: statIncreasePercent("Paralyze resist"),
    125: statIncreasePercent("Critical"),
    126: statIncreasePercent("Critical resist"),
    127: statIncreasePercent("Stun"),
    128: statIncreasePercent("Stun resist"),
    129: { name: "Use alternative action names", describe: () => ({
            text: "Apply action name changer from skill processor",
            appendDuration: true,
        }),
    },
    130: {
        name: "Post-stage clear effect",
    },
    131: {
        name: "Change weight",
    },
    132: {
        name: "Provoke",
        describe(effect, value) {
            if (value) {
                let param = Number(value.value);
                if (!isNaN(param)) {
                    return {
                        text: `Caster has provoke priority level ${filters.thousands(param, undefined, true)}`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        }
    },
    133: {
        name: "Forced stun",
    },
    134: statIncreasePercent("Physical damage taken", -1),
    135: statIncreasePercent("Magic damage taken", -1),

    137: {
        name: "Death defied",
    },
    138: {
        name: "Ignore iframes",
        describe(effect, value) {
            return {
                text: `Ignores target iframe state except when granted by effect 99`,
            };
        },
    },
    139: statIncreasePercent("MP consumption"),

    141: {
        name: "Healing effectiveness",
        describe(effect, value) {
            if (value && value.duration > 0) {
                let ratio = Number(value.value);
                if (!isNaN(ratio)) {
                    ratio -= 1;
                    if (ratio > 0) {
                        return {
                            text: `Incoming healing effects increased by ${filters.percent(ratio)}%`,
                            appendDuration: true,
                        }
                    } else if (ratio < 0) {
                        return {
                            text: `Incoming healing effects decreased by ${filters.percent(-ratio)}%`,
                            appendDuration: true,
                        }
                    }
                }
            }

            return null;
        }
    },
    142: {
        name: 'Increased Super Armor Break',
        async describeTsx(h, effect, value) {
            if (value) {
                let param = Number(value.value);
                return (
                    <span>
                        Increase super armor break on hit by { filters.percent(param) }% { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        }
    },
    143: {
        name: 'HP cannot drop below 1'
    },
    144: {
        name: "Movement-Action Speed Slow/Ice Defense",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                let slows = [0, 0, 0];
                for (let i = 0; i < split.length; ++i) {
                    let param = Number(split[i]);
                    if (!isNaN(param)) {
                        slows[i] = param;
                    }
                }

                let prefix = ["movement", "action", "ice defense"];
                let mods = slows.map((v, i) => (v == 0 ? null : `${prefix[i]} speed ${v < 0 ? 'reduced' : 'increased'} by ${filters.percent((v < 0 ? -1 : 1) * v)}%`))
                    .filter((v) => v != null);

                return {
                    text: "Target " + mods.join("; "),
                    appendDuration: true,
                };
            }

            return null;
        },
    },
    146: {
        name: 'Time Stop',
        async describeTsx(h, effect, value) {
            if (value) {
                if (Number(value.value) !== 0) {
                    return (
                        <span>
                            Time stopped with A-D mech { durationTsx(h, value.duration) }
                        </span>
                    )
                } else {
                    return (
                        <span>
                            Time stopped { durationTsx(h, value.duration) }
                        </span>
                    )
                }
            }
            
            return null;
        },
    },
    148: {
        name: 'Reset Aggro',
    },
    150: {
        name: 'State Effect Immunity',
        async describeTsx(h, effect, value) {
            if (value) {
                const values = value.value.split(';').map(Number).filter((v) => !isNaN(v) && v > 0).map((v) => {
                    const b = getBlow(v);
                    if (!b) {
                        return `Blow ${v}`;
                    }
                    
                    return `${b.name} (${v})`;
                });
                
                return (
                    <span>
                        Immune to effects { values.join(', ') } { durationTsx(h, value.duration) }
                    </span>
                )
            }
            
            return null;
        },
    },
    151: {
        name: "Camera distortion effect",
        // TODO
    },

    159: {
        name: "Execute damage",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                let thresholdHpPercent = Number(split[0]);
                let bonusDamagePerPercentHpUnderThreshold = Number(split[1]);

                return {
                    text: `Upon striking an enemy under ${filters.percent(thresholdHpPercent)}% HP, deal ${filters.percent(bonusDamagePerPercentHpUnderThreshold)}% skill damage per 1% missing HP below ${filters.percent(thresholdHpPercent)}% as additional damage`,
                };
            }

            return null;
        }
    },

    154: {
        name: "Grant immunity to skill",
        async describeTsx(h, effect, value) {
            if (value) {
                const split = value.value.split(';');
                const skillIds = split.map(Number);
                const sendResist = split[split.length - 1] == 'Resist';
                
                const skillNames = skillListTsxIds(h, skillIds);
            
                return (
                    <span>
                        Grants immunity to
                        { skillNames }
                        { sendResist ? ', displaying Resist message when resisted' : undefined }
                        { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },
    
    162: {
        name: 'Breaking Point',
    },

    166: {
        name: "Increased damage against afflicted targets",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                if (split.length >= 2) {
                    let effectId = Number(split[0]);
                    let bonus = Number(split[1]);

                    let blow = getBlow(effectId);
                    let blowName = blow ? blow.name : `Effect ${effectId}`;

                    return {
                        text: `Attacks against targets afflicted with ${blowName} take ${filters.percent((bonus < 0 ? -1 : 1) * bonus)}% ${bonus < 0 ? "less" : "more"} damage`,
                        appendDuration: true,
                    };
                }
            }
            return null;
        }
    },

    167: {
        name: "Set summon skills' level",
        describe(effect, value) {
            if (value) {
                return {
                    text: `The summon's skills will be level ${value.value}`
                }
            }

            return null;
        },
    },

    169: {
        name: "Increased damage when critting",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                if (split.length >= 2) {
                    let rate = Number(split[0]);
                    let bonus = Number(split[1]);

                    return {
                        text: `Critical attacks deal ${filters.percent((bonus < 0 ? -1 : 1) * bonus)}% ${bonus < 0 ? "less" : "more"} damage ${filters.percent(rate)}% of the time`,
                        appendDuration: true,
                    };
                }
            }
            return null;
        }
    },

    171: {
        name: "Adjust skill cooldown",
        async describeTsx(h, effect, value) {
            if (value) {
                let params = value.value;
                let split = params.split("_");
                let split2 = split[0].split(";");
                let type = Number(split2[0]);
                let ratio = Number(split2[1]);

                let freeSkills = split.length > 1 ? (split[1].split(";")
                    .map((v) => Number(v))) :
                    [];

                
                const skillNames = await skillListTsxIds(h, freeSkills);
            
                let list = null;
                if (freeSkills.length) {
                    list = <span>for {skillNames}</span>;
                }
                
                return (
                    <span>
                        { type ? 'Passive' : 'Active' } skill cooldowns { list } are reduced by {filters.percent(1 - ratio)}% { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },
    172: {
        name: 'Additional Damage on State Effect',
        async describeTsx(h, effect, value) {
            if (value) {
                let v = value.value;
                if (v.startsWith('[')) {
                    v = v.substring(1);
                }
                if (v.endsWith(']')) {
                    v = v.substring(0, v.length - 1);
                }
                
                const split = v.split(';');
                
                const chance = Number(split[0]);
                const requiredBlowId = Number(split[1]);
                const shouldConsume = Number(split[2]) !== 0;
                const bonusDamage = Number(split[3]);
                
                let blowDesc = `State Effect ${requiredBlowId}`;
                const requiredBlow = Blows[requiredBlowId];
                if (requiredBlow) {
                    blowDesc = `${requiredBlow.name} (${requiredBlowId})`;
                }
                
                const consume = shouldConsume ? ', consuming the mark' : '';
                
                return (
                    <span>
                        When hitting an enemy afflicted with {blowDesc}, deal {filters.percent(bonusDamage)}% additional damage at a {filters.percent(chance)}% rate{consume} { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        }
    },

    173: {
        name: "Ice Barrier",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                if (v.startsWith("[")) {
                    let split = v.split("][");
                    let param1 = split[0].substring(1);
                    split = param1.split(";");
                    let scaling = Number(split[0]);
                    return {
                        text: `Create a shield with ${filters.percent(scaling)}% of your magic damage as health`,
                        appendDuration: true,
                    };
                }
            }

            return null;
        },
    },

    174: {
        name: 'Buff steal'
    },
    175: {
        name: 'Push',
    },
    
    179: {
        name: "On Hit Action",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(",");
                let list = split.join(", then ");
                return {
                    text: `Upon striking a target, change action to ${list}`,
                }
            }

            return null;
        }
    },
    184: {
        name: 'Unused marker buff',
    },
    185: {
        name: 'Unused marker buff',
    },
    200: statIncrease("PDMG"),

    202: statIncrease("MDMG"),

    206: {
        name: 'Action change %',
    },
    
    211: {
        name: "Clear status effect",
        async describeTsx(h, effect, value) {
            if (value) {
                const skillIds = value.value.split(';').map(Number);
                
                let skills = await skillListTsxIds(h, skillIds);
            
                return (
                    <span>
                        Remove effects applied by { skills } { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },

    215: {
        name: "Command summon use skill",
        async describe(effect, value) {
            let skill = value && Number(value.value) || 0;
            let skillName = '';
            if (skill) {
                skillName = (await getSkillSafe(skill)).name.message;
            }

            return {
                text: `Command summon to use skill '${skillName}' (${skill})`,
            }
        },
    },

    218: {
        name: "Binding",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");

                let rate = Number(split[0]);
                let durability = Number(split[1]);
                let action = split[2];
                let canAD = split[3] == "1";

                return {
                    text: `${filters.percent(rate)}% chance to bind, with action ${action}, ${canAD ? "A-D to escape" : ""}`,
                    appendDuration: true,
                }
            }

            return null;
        },
    },
    
    220: {
        name: 'Acceleration',
    },

    224: {
        name: "Cannot perform action",
        describe: (effect, value) => ({
            text: `Unable to perform action(s) ${value && value.value.split(";").join(", ")}`,
            appendDuration: true,
        }),
    },

    225: {
        name: "Confusion",
        describe: (effect, value) => {
            if (value) {
                const mode = Number(value.value);
                switch (mode) {
                    case 2: {
                        return {
                            text: `Keyboard controls will be reversed`,
                            appendDuration: true,
                        };
                    }
                    case 3: {
                        return {
                            text: `Mouse controls will be reversed`,
                            appendDuration: true,
                        };
                    }
                    case 1: {
                        return {
                            text: `Mouse and keyboard controls will be reversed`,
                            appendDuration: true,
                        };
                    }
                }
            }
            
            return null;
        },
    },

    228: {
        name: 'Drunken',
    },
    
    232: {
        name: "Transform",
        async describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");

                let monsterId = Number(split[0]);
                let initialAction = split[1];

                // todo
                let monsterName = `Monster ${monsterId}`;

                return {
                    text: `Transform into ${monsterName}, initial action "${initialAction}"`,
                    appendDuration: true,
                };
            }

            return null;
        }
    },

    233: {
        name: "Kill",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let flag = v == "0";

                return {
                    text: `Guaranteed kill`,
                    appendDuration: true,
                };
            }

            return null;
        },
    },
    234: statIncrease("CRIT"),

    241: {
        name: "Screen flash",
        describe: () => ({ text: "Screen flash", appendDuration: true }),
    },
    
    
    247: {
        name: 'Puppet',
    },

    251: statIncreasePercent("Critical rate"),

    252: {
        name: "Grant immunity",
        async describeTsx(h, effect, value) {
            if (value) {
                const skillIds = value.value.split(';').map(Number);
                
                const skills = await skillListTsxIds(h, skillIds);
            
                return (
                    <span>
                        Grants immunity from the effects applied by
                        { skills }
                        { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },

    254: {
        name: "Force aggro target",
        async describe(effect, value) {
            if (value) {
                const split = value.value.split(";");
                
                const findRandomTarget = Number(split[0]) === 1;
                const onlyPlayers = Number(split[1]) === 1;
                const increaseAggroAmount = Number(split[2]);
                const stateEffectId = Number(split[3]);

                // TODO await state effect
                const stateEffectDesc = `State Effect ${stateEffectId}`;

                return {
                    text: `Forces ${findRandomTarget ? 'a random' : 'the current'} ${onlyPlayers ? 'player' : 'actor'} as the aggro target for ${filters.milliseconds(value.duration)}s, adjusting aggro by ${increaseAggroAmount}, displaying state effect ${stateEffectDesc}`,
                    appendDuration: false,
                };
            }

            return null;
        }
    },

    275: {
        name: "Disallow healing",
        async describeTsx(h, effect, value) {
            if (value) {
                const skillIds = value.value.split(';').map(Number);
                
                const skills = await skillListTsxIds(h, skillIds);
                
                if (skillIds.length) {
                    return (
                        <span>
                            Disallows healing except from
                            <ul class="inline-list">{ skills }</ul>
                            { durationTsx(h, value.duration) }
                        </span>
                    );
                } else {
                    return (<span>Disallow healing from all sources { durationTsx(h, value.duration) }</span>);
                }
            }
            
            return null;
        },
    },
    276: {
        name: 'Apply state effect on skill use',
        async describeTsx(h, effect, value) {
            if (value) {
                const v = value.value;
                const split = v.split('_');
                const stateEffectInfo = split[0];
                const skillList = split[1].split(',').map(Number).filter((v) => !isNaN(v));

                const skills = await skillListTsxIds(h, skillList);
                
                const stateEffectSplit = stateEffectInfo.split('/');
                const stateEffectId = Number(stateEffectSplit[0]);
                const stateEffectParam = stateEffectSplit[1];
                
                const subEffect = await renderChild(h, stateEffectId, stateEffectParam, value.duration, effect);
                
                return (
                    <span>Apply state effect { subEffect } when using { skills } { durationTsx(h, value.duration) }</span>
                );
            }

            return null;
        },
    },
    278: {
        name: "Execute damage EX",
        describe(effect, value) {
            if (value) {
                let v = value.value;
                let split = v.split(";");
                let thresholdHpPercent = Number(split[0]);
                let hpThresholdRatio = Number(split[1]);
                let damageBoost = Number(split[2]);
                let reverse = Number(split[3]) === 1;

                return {
                    text: `Upon striking an enemy ${reverse ? 'over' : 'under'} ${filters.percent(thresholdHpPercent)}% HP, gain ${filters.percent(damageBoost)}% damage per ${filters.percent(hpThresholdRatio)}% HP ${reverse ? 'above' : 'below'} ${filters.percent(thresholdHpPercent)}%`,
                };
            }

            return null;
        }
    },

    288: {
        name: "Apply effect on successful hit of type",
        async describeTsx(h, effect, value) {
            if (value) {
                const vstr: string = value.value;
                const split = vstr.split('][').map((v) => v.replaceAll(/\]|\[/g, ''));
                const settings = split[0];
                const stateEffect = split[1];
                const [attackType, element, applyType, unk4, rate, duration, unk7, cooldown, unk9] = settings.split(';').map(Number);
                
                const attackTypeStr = attackType >= 0 ? (['physical', 'magical'][attackType] || String(attackType)) : '';
                const elementStr = element >= 0 ? (['fire', 'water', 'light', 'dark'][element] || String(element)) : '';
                const applyTypeStr = ['self', 'targets', 'all', 'enemies', 'allies'][applyType] || String(applyType);
                
                const stateEffectSplit = stateEffect.split('|');
                const stateEffectId = Number(stateEffectSplit[0]);
                const stateEffectParams = stateEffectSplit[1];
                
                const sub = await renderChild(h, stateEffectId, stateEffectParams, duration, effect);
                
                return (
                    <span>After successfully hitting {attackTypeStr} {elementStr} attacks, apply {sub} to {applyTypeStr} at {rate}% rate for {filters.milliseconds(duration)}s with a {filters.milliseconds(cooldown)}s cooldown { durationTsx(h, value.duration) }</span>
                );
            }
            
            return null;
        },
    },

    291: {
        name: "All active skill cooldown refund",
        describe(effect, value) {
            if (value) {
                return {
                    text: `Refund ${value.value}s cooldown to all active skills`,
                    appendDuration: true,
                };
            }

            return null;
        }
    },

    296: {
        name: "Apply state effect on skill cast",
        async describeTsx(h, effect, value) {
            if (value) {
                const vstr: string = value.value;
                
                const lastBarIndex = vstr.lastIndexOf('|');
                const front = vstr.slice(0, lastBarIndex);
                const back = vstr.slice(lastBarIndex + 1, vstr.length);
                
                const [settings, ...effects] = front.split('][').flatMap((v) => v.replace(/\]|\[/g, ''));
                const [rate, duration, cooldown, targetType] = settings.split(';').map(Number);
                const childEffects = [];
                for (const subEffect of effects) {
                    const [stateEffectId, stateEffectParameters] = subEffect.split('/');
                    let childEffect = await renderChild(h, Number(stateEffectId), stateEffectParameters, duration, effect);
                    childEffects.push((<li>{ childEffect }</li>));
                }
                
                const appliedSkills = back.replace(/\]|\[/g, '').split(';').map(Number).filter((v) => !isNaN(v));
                const skills = await skillListTsxIds(h, appliedSkills);
                
                let targetTypeName = String(targetType);
                switch (targetType) {
                    case 0: {
                        targetTypeName = 'self';
                        break;
                    }
                    case 1: {
                        targetTypeName = 'enemies';
                        break;
                    }
                    case 2: {
                        targetTypeName = 'allies';
                        break;
                    }
                    case 3: {
                        targetTypeName = 'party';
                        break;
                    }
                    case 4: {
                        targetTypeName = 'all';
                        break;
                    }
                }
                
                return (
                    <span>
                        Apply state effect(s) <ul class='unmarked-list'>{ childEffects }</ul> to { targetTypeName } at a rate of { rate }% for { filters.milliseconds(duration) }s after using { skills } { durationTsx(h, value.duration)}
                    </span>
                );
            }
            
            return null;
        },
    },
    
    297: {
        name: "Apply state effect on successful hit from skill",
        async describeTsx(h, effect, value) {
            if (value) {
                const vstr: string = value.value;
                
                const lastBarIndex = vstr.lastIndexOf('|');
                const front = vstr.slice(0, lastBarIndex);
                const back = vstr.slice(lastBarIndex + 1, vstr.length);
                
                const [settings, ...effects] = front.split('][').flatMap((v) => v.replace(/\]|\[/g, ''));
                const [rate, duration, cooldown, targetType] = settings.split(';').map(Number);
                const childEffects = [];
                for (const subEffect of effects) {
                    const [stateEffectId, stateEffectParameters] = subEffect.split('/');
                    let childEffect = await renderChild(h, Number(stateEffectId), stateEffectParameters, duration, effect);
                    childEffects.push((<li>{ childEffect }</li>));
                }
                
                const appliedSkills = back.replace(/\]|\[/g, '').split(';').map(Number).filter((v) => !isNaN(v));
                const skills = await skillListTsxIds(h, appliedSkills);
                
                let targetTypeName = String(targetType);
                switch (targetType) {
                    case 0: {
                        targetTypeName = 'self';
                        break;
                    }
                    case 1: {
                        targetTypeName = 'enemies';
                        break;
                    }
                    case 2: {
                        targetTypeName = 'allies';
                        break;
                    }
                    case 3: {
                        targetTypeName = 'party';
                        break;
                    }
                    case 4: {
                        targetTypeName = 'all';
                        break;
                    }
                }
                
                return (
                    <span>
                        Apply state effect(s) <ul class='unmarked-list'>{ childEffects }</ul> to { targetTypeName } at a rate of { rate }% for { filters.milliseconds(duration) }s after successfully hitting { skills } { durationTsx(h, value.duration)}
                    </span>
                );
            }
            
            return null;
        },
    },
    298: {
        name: 'Change skin',
        async describeTsx(h, effect, value) {
            if (value) {
                const vstr: string = value.value;
                const tk = new TokenParser(vstr);
                const params = tk.takeAll(';');
                
                let actorTableId = 0;
                let mainHandWeaponId = 0;
                let offHandWeaponId = 0;
                let lastPlayEffectId = 0;
                let soundParam = '';
                let useSkinOption = false;
                
                if (params.length >= 4) {
                    actorTableId = Number(params[0]);
                    mainHandWeaponId = Number(params[1]);
                    offHandWeaponId = Number(params[2]);
                    lastPlayEffectId = Number(params[3]);
                }
                
                if (params.length >= 5) {
                    soundParam = params[4];
                }
                
                if (params.length >= 6) {
                    useSkinOption = Number(params[5]) === 1;
                }
                
                return (
                    <span>Transform into actor ID { actorTableId } with mainhand weapon { mainHandWeaponId }, offhand weapon { offHandWeaponId }, playing state VFX { lastPlayEffectId }{soundParam ? ', with sound param ' + soundParam : ''}, { useSkinOption ? 'respecting' : 'ignoring'} the game transform setting</span>
                )
            }
            
            return null;
        }
    },
    301: {
        name: "DPS bar",
        async describe(effect, value) {
            if (value) {
                let params = value.value;
                let split = params.split(";");
                
                let type = Number(split[0]);
                let durability = Number(split[1]);
                let skillIdIfBroken = Number(split[2]);
                let unkA = split[3];
                let unkB = split[4];
                let unkC = split[5];

                let skill = await getSkillSafe(skillIdIfBroken);

                let duration = value.duration == 0 ? "" : ` for ${filters.milliseconds(value.duration)}s`;

                let typeStr = `type ${type}`;
                switch (type) {
                    case 1: typeStr = "blue"; break;
                    case 3: typeStr = "red"; break;
                }

                return {
                    text: `Display a ${typeStr} DPS bar with a durability of ${filters.thousands(durability)}${duration}. Use ${skill.name.message} upon breaking. ${unkA},${unkB},${unkC}`,
                    appendDuration: false,
                }
            }

            return null;
        }
    },

    306: statIncreasePercent("Elemental resistance"),
    307: statIncreasePercent("Elemental ATK"),
    
    309: {
        name: 'Increase ATK after successful attack'
    },
    310: statIncreasePercent("ATK"),

    315: {
        name: "Heal over time using MATK",
        describe(effect, value) {
            if (value) {
                const split = value.value.split(";").map(Number);
                const [unk1, scaling, unk2, unk3, interval, duration] = split;

                return {
                    text: `Heal ${filters.percent(scaling)}% of MATK as HP every ${filters.milliseconds(interval)}s over ${filters.milliseconds(duration)}s`,
                    appendDuration: true,
                };
            }

            return null;
        },
    },
    316: {
        name: 'Mark summon target',
    },
    317: {
        name: 'Force summons to target marked target'
    },
    319: {
        name: 'Duplicate hit explosion and transfer on disconnect',
        async describeTsx(h, effect, value) {
            if (value) {
                const damage = Number(value.value);
                return (
                    <span>
                        If effect is applied twice, deal {filters.thousands(damage)} damage. If the bearer of this effect disconnects, transfer it to another player.
                    </span>
                )
            }
            
            return null;
        },
    },
    322: {
        name: 'Apply skill on successful attack',
        async describeTsx(h, effect, value) {
            if (value) {
                const [skillId, cooldown, rate] = value.value.split(";").map(Number);
                const skill = await getSkillSafe(skillId);
                const skillLink = (<a href={`/skills/${skill.id}`} target={'_blank'}>{skill.name.message} ({skill.id})</a>);
                                
                return (
                    <span>
                        Apply {skillLink} at a {rate}% chance on successfull attack with a {filters.milliseconds(cooldown)} cooldown { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },
    323: {
        name: 'Apply skill on skill cast',
        async describeTsx(h, effect, value) {
            if (value) {
                const [skillId, cooldown, rate] = value.value.split(";").map(Number);
                const skill = await getSkillSafe(skillId);
                const skillLink = (<a href={`/skills/${skill.id}`} target={'_blank'}>{skill.name.message} ({skill.id})</a>);
                                
                return (
                    <span>
                        Apply {skillLink} at a {rate}% chance on skill cast with a {filters.milliseconds(cooldown)} cooldown { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },
    
    325: {
        name: "Bleed",
        describe(effect, value) {
            if (value) {
                const split = value.value.split(";");
                const rate = Number(split[0]) || 0;
                const coeff = Number(split[1]) || 0;
                const flatDmg = Number(split[2]) || 0;
                const absoluteDmg = Number(split[3]) || 0;

                return {
                    text: `${filters.percent(rate)}% chance to afflict target with ${filters.percent(coeff)}%/${filters.thousands(flatDmg)} DMG/${filters.thousands(absoluteDmg)} +DMG ATK bleed`,
                    appendDuration: true,
                };
            }

            return null;
        },
    },
    326: {
        name: 'Cannot gain aggro',
    },
    331: {
        name: 'Use skill when enemy killed',
        async describeTsx(h, effect, value) {
            if (value) {
                const [skillId, cooldown, rate] = value.value.split(";").map(Number);
                const skill = await getSkillSafe(skillId);
                const skillLink = (<a href={`/skills/${skill.id}`} target={'_blank'}>{skill.name.message} ({skill.id})</a>);
                                
                return (
                    <span>
                        Use {skillLink} at a {rate}% chance when you kill an enemy with a {filters.milliseconds(cooldown)} cooldown { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },

    332: {
        name: 'Use skill when SA broken',
        async describeTsx(h, effect, value) {
            if (value) {
                const [skillId, cooldown, rate] = value.value.split(";").map(Number);
                const skill = await getSkillSafe(skillId);
                const skillLink = (<a href={`/skills/${skill.id}`} target={'_blank'}>{skill.name.message} ({skill.id})</a>);
                                
                return (
                    <span>
                        Use {skillLink} at a {rate}% chance when super armor is broken with a {filters.milliseconds(cooldown)} cooldown { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },

    333: {
        name: 'Reduce skill CD',
        async describeTsx(h, effect, value) {
            if (value) {
                const split = value.value.split(";");
                const cooldownPercent = Number(split[0]);
                const cooldownFlat = Number(split[1]);
                const skillIds = split[2].split(',').map(Number).filter((v) => !isNaN(v));
                
                const skills = await skillListTsxIds(h, skillIds);
                
                return (
                    <span>
                        Reduces cooldown of { skills } by {filters.percent(cooldownPercent)}% and {filters.milliseconds(cooldownFlat)}s { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },
    
    345: {
        name: "Enhance Skills",
        async describeTsx(h, effect, value) {
            if (value) {
                let params = value.value;
                const slashSplit = params.split('|');
                let split = slashSplit[0].split(";");

                /*if (isDuck()) {
                    const pairCount = Math.floor(split.length / 2);
                    
                    
                } else */{
                    let freeSkills = split.map((v) => Number(v));
                    let skillNames = (await Promise.all(freeSkills
                        .filter((v) => v > 0 && !isNaN(v))
                        .map(async (v) => {
                            return await getSkillSafe(v);
                        })
                        .map(async (v) => {
                            const av = await v;
                            return {
                                desc: `${av.name.message} (${av.id})`,
                                id: av.id,
                            };
                        })
                        .map(async (v) => {
                            const av = await v;
                            const url = `/skills/${av.id}`;
                            return (
                                <a href={url}>{av.desc}</a>
                            );
                        })));

                    let pairs = skillNames
                        .map((v, i, a) => {
                            if (i % 2 == 1) {
                                return (
                                    <tr>
                                        <th>{a[i - 1]}</th>
                                        <td>{v}</td>
                                    </tr>
                                );
                            }

                            return null;
                        })
                        .filter((v) => v != null);

                    return (
                        <div>
                            <div>Enhance the following skills</div>
                            <table>
                                <thead>
                                    <th>Original Skill</th>
                                    <th>Enhanced Skill</th>
                                </thead>
                                <tbody>
                                    { pairs }
                                </tbody>
                            </table>
                            { durationTsx(h, value.duration) }
                        </div>
                    );
                }
            }

            return null;
        }
    },

    347: {
        name: 'Mark',
        async describeTsx(h, effect, value) {
            return <span>Marks the target (used by BFP etc) { durationTsx(h, value && value.duration || 0) }</span>;
        }
    },
    349: {
        name: "Passive bubble generation",
        async describe(effect, value) {
            if (value) {
                let params = value.value;
                let split = params.split(";");

                let interval = Number(split[0]);
                let bubbleId = Number(split[1]);

                // todo load bubble info
                let bubbleName = `Bubble ${bubbleId}`;
                
                try {
                    const bubble = await TableProvider.getTableRow<UiStringResolvedTableRow<ISkillBubbleDefineTableRow, '_BubbleNameID'>>('skillbubbledefine', bubbleId, undefined, {
                        uiresolve: ['_BubbleNameID']
                    });
                    if (bubble._BubbleNameID_txt) {
                        bubbleName = `${bubble._BubbleNameID_txt} (${bubbleId})`;
                    }
                } catch (e) {
                }

                return {
                    text: `Every ${filters.milliseconds(interval)}s, gain 1x ${bubbleName}`,
                }
            }
            return null;
        }
    },
    350: {
        name: "Passive bubble consumption",
        async describe(effect, value) {
            if (value) {
                let params = value.value;
                let split = params.split(";");

                let interval = Number(split[0]);
                let bubbleId = Number(split[1]);

                // todo load bubble info
                let bubbleName = `Bubble ${bubbleId}`;
                
                try {
                    const bubble = await TableProvider.getTableRow<UiStringResolvedTableRow<ISkillBubbleDefineTableRow, '_BubbleNameID'>>('skillbubbledefine', bubbleId, undefined, {
                        uiresolve: ['_BubbleNameID']
                    });
                    if (bubble._BubbleNameID_txt) {
                        bubbleName = `${bubble._BubbleNameID_txt} (${bubbleId})`;
                    }
                } catch (e) {
                }

                return {
                    text: `Every ${filters.milliseconds(interval)}s, consume 1x ${bubbleName}`,
                }
            }
            return null;
        }
    },
    351: {
        name: 'Disable skills',
        async describeTsx(h, effect, value) {
            if (value) {
                let params = value.value;
                let split = params.split("/");

                const skillIds = split.map((v) => Number(v));
                
                const skills = await skillListTsxIds(h, skillIds);
                
                return (
                    <div>
                        Disables skills { skills } { durationTsx(h, value.duration) }
                    </div>
                );
            }
            
            return null;
        }
    },
    356: {
        name: "Skill cooldown reduction on skill hit",
        async describeTsx(h, effect, value) {
            if (value) {
                let params = value.value;
                let split = params.split(";");
                let recoveredSkills = split[0].split(",").map((v) => Number(v));
                let recoverySkills = split[1].split(",").map((v) => Number(v));
                let recoveryAmount = Number(split[2]);
    
                const recoveredSkillNames = await skillListTsxIds(h, recoveredSkills);
                const recoverySkillNames = await skillListTsxIds(h, recoverySkills);
                

                return (
                    <span>
                        Recover {filters.milliseconds(recoveryAmount)} CD of { recoveredSkillNames } when { recoverySkillNames } hit { durationTsx(h, value.duration) }
                    </span>
                );
            }

            return null;
        }
    },
    358: {
        name: 'Decrease skill cooldown',
        async describeTsx(h, effect, value) {
            if (value) {
                const tokenParser = new TokenParser(value.value);
                const skillId = Number(tokenParser.takeNext(';'));
                const cooldown = Number(tokenParser.takeNext(';'));
                
                const skill = await getSkillSafe(skillId);
                return (
                    <span>
                        Reduce the cooldown of {skillTsx(h, skill)} {cooldown < 0 ? 'fully' : 'by ' + filters.milliseconds(cooldown)}
                    </span>
                );
            }

            return <span></span>;
        }
    },
    360: {
        name: 'Force all defenses to zero',
    },
    362: {
        name: 'Turn on aura skill',
        async describeTsx(h, effect, value) {
            if (value) {
                const skillId = Number(value.value);
                
                const skill = await getSkillSafe(skillId);
                return (
                    <span>
                        Turn on aura skill {skillTsx(h, skill)} {durationTsx(h, value.duration)}
                    </span>
                );
            }

            return <span></span>;
        }
    },
    370: {
        name: "Remove buff when bubble count drops to value",
        async describeTsx(h, effect, value) {
            if (value) {
                const [bubbleId, count, skillId] = value.value.split(";").map(Number);
                const skill = await getSkillSafe(skillId);
                const skillLink = (<a href={`/skills/${skill.id}`} target={'_blank'}>{skill.name.message} ({skill.id})</a>);
                
                let bubbleName = `Bubble ${bubbleId}`;
                
                try {
                    const bubble = await TableProvider.getTableRow<UiStringResolvedTableRow<ISkillBubbleDefineTableRow, '_BubbleNameID'>>('skillbubbledefine', bubbleId, undefined, {
                        uiresolve: ['_BubbleNameID']
                    });
                    if (bubble._BubbleNameID_txt) {
                        bubbleName = `${bubble._BubbleNameID_txt} (${bubbleId})`;
                    }
                } catch (e) {
                }
                
                return (
                    <span>
                        Removes effects granted by {skillLink} if {bubbleName} drops to {count} { durationTsx(h, value.duration) }
                    </span>
                );
            }
            
            return null;
        },
    },
    371: {
        name: 'Big head',
        async describeTsx(h, effect, value) {
            if (value) {
                const scale = Number(value.value);
                
                return (<span>
                    Scale head bone by {filters.percent(scale)}%
                </span>);
            }

            return <span></span>;
        }
    },
    372: {
        name: "Increase stat by percent of another stat",
        describe(effect, value) {
            if (value) {
                let params = value.value;
                let split = params.split(";");
                let sourceStat = Number(split[0]);
                let rate = Number(split[1]);
                let destStat = Number(split[2]);

                function statToName(id: number): string {
                    switch (id) {
                        case 0: return "STR";
                        case 2: return "INT";
                        case 5: return "PDMG";
                        case 6: return "MDMG";
                        case 7: return "PDEF";
                    }

                    return `Stat ${id}`;
                };

                // todo
                let sourceStatName = statToName(sourceStat);
                let destStatName = statToName(destStat);

                return {
                    text: `Convert ${filters.percent(rate)}% of ${sourceStatName} to ${destStatName}`,
                    appendDuration: true,
                };
            }

            return null;
        },
    },
    373: {
        name: 'Additional damage when resisted',
        async describeTsx(h, effect, value) {
            if (value) {
                const tokenParser = new TokenParser(value.value);
                const stateEffectId = Number(tokenParser.takeNext(';'));
                const modifier = Number(tokenParser.takeNext(';'));
                const parentBlow = Blows[stateEffectId];
                const parentBlowName = parentBlow ? parentBlow.name : `State Effect ${stateEffectId}`;

                return (<span>
                    Deal an additional {filters.percent(modifier)}% damage if state effect {parentBlowName} is resisted
                </span>);
            }

            return <span></span>;
        }
    },
    375: {
        name: 'Extend buff duration',
        async describeTsx(h, effect, value) {
            if (value) {
                const split = value.value.split(';');
                const skillList = split[0].split(',').map((v) => Number(v)).filter((v) => !isNaN(v));
                const duration = Number(split[1]);
                const maxDuration = Number(split[2]);
                
                const skills = await skillListTsxIds(h, skillList);
                
                let extendo = null;
                if (!isNaN(maxDuration)) {
                    extendo = (
                        <span>up to a maximum of { filters.milliseconds(maxDuration) }s</span>
                    );
                }
                
                return (
                    <span>Extends the duration of { skills } by { filters.milliseconds(duration) }s { extendo }</span>
                );
            }

            return <span></span>;
        }
    },
    376: {
        name: 'Apply visual state effect ID',
        async describeTsx(h, effect, value) {
            if (value) {
                let stateEffectId = Number(value.value);
                if (isNaN(stateEffectId)) {
                    return (<span>
                        Apply {value.value} action visually from playercommoneffect.act { durationTsx(h, value.duration) }
                    </span>);
                } else {
                    // TODO
                    let stateEffectName = `VFX effect ${stateEffectId}`;

                    return (<span>
                        Apply {stateEffectName} { durationTsx(h, value.duration) }
                    </span>);
                }
            }

            return <span></span>;
        }
    },
    377: {
        name: 'Set global cooldown',
        async describeTsx(h, effect, value) {
            if (value) {
                const tokenParser = new TokenParser(value.value);
                const groupId = Number(tokenParser.takeNext(';'));
                const cooldownMs = Number(tokenParser.takeNext(';'));

                return (<span>
                    Put skill group {groupId} on global cooldown for {filters.milliseconds(cooldownMs)}s
                </span>);
            }

            return <span></span>;
        }
    },
    382: {
        name: 'Check for parent effect',
        async describeTsx(h, effect, value) {
            if (value) {
                const tokenParser = new TokenParser(value.value);
                const parentStateEffect = Number(tokenParser.takeNext(';'));
                const skillId = Number(tokenParser.takeNext(';'));
                const skill = await getSkillSafe(skillId);
                
                const parentBlow = Blows[parentStateEffect];
                const parentBlowName = parentBlow ? parentBlow.name : `State Effect ${parentStateEffect}`;
                return (
                    <span>
                        Apply effects from {skillTsx(h, skill)} if state effect {parentBlowName} ({parentStateEffect}) is applied (only works for monsters)
                    </span>
                )
            }

            return null;
        }
    },
    383: {
        name: 'Transform Monster',
        async describeTsx(h, effect, value) {
            if (value) {
                const tokenParser = new TokenParser(value.value);
                const actorId = Number(tokenParser.takeNext(';'));
                const lastPlayEffectId = Number(tokenParser.takeNext(';'));
                
                return (
                    <span>
                        Transform into monster ActorID {actorId} and play VFX efffect {lastPlayEffectId} {durationTsx(h, value.duration)}
                    </span>
                )
            }

            return null;
        }
    },
    384: {
        name: 'Change skill damage scaling',
        async describeTsx(h, effect, value) {
            if (value) {
                let skillId = Number(value.value);
                const skill = await getSkillSafe(skillId);
                return (<span>
                    Change this skill's damage scaling to that of {skillTsx(h, skill)} {durationTsx(h, value.duration)}
                </span>);
            }

            return null;
        }
    },
    385: {
        name: 'WASD Select',
        async describeTsx(h, effect, value) {
            if (value) {
                const tokenParser = new TokenParser(value.value);
                let skills = tokenParser.takeAll(';').map(Number);
                
                const [wSk, aSk, sSk, dSk] = (await Promise.allSettled(skills.map(async (v) => {
                    if (isNaN(v) || v <= 0)
                    {
                        return null;
                    }
                    
                    return await getSkillSafe(v);
                }))).map((v) => {
                    if (v.status === 'fulfilled') {
                        return v.value;
                    } else {
                        return null;
                    }
                });
                
                return (<span>
                    Pressing a directional key uses a specific skill:
                    <ul>
                        <li>W: { wSk ? skillTsx(h, wSk) : '(none)' }</li>
                        <li>A: { aSk ? skillTsx(h, aSk) : '(none)' }</li>
                        <li>S: { sSk ? skillTsx(h, sSk) : '(none)' }</li>
                        <li>D: { dSk ? skillTsx(h, dSk) : '(none)' }</li>
                    </ul>
                    {durationTsx(h, value.duration)}
                </span>);
            }

            return <span></span>;
        }
    },
    386: {
        name: 'Hypnosis',
        async describeTsx(h, effect, value) {
            if (value) {
                const tokenParser = new TokenParser(value.value);
                let tokens = tokenParser.takeAll(';');
                
                let chance = 0;
                let shakeReduceTime = 0;
                let slowValue = 0;
                let useSpacebar = false;
                let spacebarSpeed = 0;
                let spacebarRound = 0;
                let spacebarSize = 0;
                
                if (tokens.length >= 3) {
                    chance = Number(tokens[0]);
                    shakeReduceTime = Number(tokens[1]);
                    slowValue = Number(tokens[2]);
                }
                if (tokens.length >= 4) {
                    chance = Number(tokens[0]);
                    shakeReduceTime = Number(tokens[1]);
                    slowValue = Number(tokens[2]);
                    useSpacebar = Number(tokens[3]) > 0;
                    if (useSpacebar && tokens.length === 7) {
                        spacebarSpeed = Number(tokens[4]);
                        spacebarRound = Number(tokens[5]);
                        spacebarSize = Number(tokens[6]);
                    }
                }
                
                if (useSpacebar) {
                    return (
                        <span>
                            Apply spacebar check, force into Hit_Hypnosis at a {chance}% rate, modify movement speed by {filters.percent(slowValue)}%. 
                            The spacebar check has speed {spacebarSpeed}, {spacebarRound} rounds, and the hit zone is size {spacebarSize}. {durationTsx(h, value.duration)}
                        </span>
                    )
                    
                } else {
                    return (
                        <span>
                            Applyz A-D check, force into Hit_Hypnosis at a {chance}% rate, modify movement speed by {filters.percent(slowValue)}%. 
                            Each A-D cycle reduces the time by {filters.milliseconds(shakeReduceTime)}s. {durationTsx(h, value.duration)}
                        </span>
                    )
                }
            }
            
            return null;
        },
    },
    387: {
        name: 'Lock camera zoom',
    },
    389: statIncreasePercent("Final damage"),
    390: {
        name: 'Apply highest elemental stat',
        async describeTsx(h, effect, value) {
            if (value) {
                const tokenParser = new TokenParser(value.value);
                let elements = tokenParser.takeAll(';').map(Number).map((v) => {
                    switch (v) {
                        case 0:
                            return 'all elements';
                        case 1:
                            return 'fire';
                        case 2:
                            return 'ice';
                        case 3:
                            return 'light';
                        case 4:
                            return 'dark';
                        default:
                            return `${v}`;
                    }
                });
                
                return (
                    <span>
                        Use the highest elemental attack out of {elements.join(', ')} for those elements {durationTsx(h, value.duration)}
                    </span>
                )
            }
            
            return null;
        }
    },
    391: {
        name: 'Marker effect',
    },
    392: {
        name: 'Marker effect',
    },
    393: {
        name: 'Marker effect',
    },
    394: {
        name: 'Marker effect',
    },
    395: {
        name: 'Marker effect',
    },
    396: {
        name: 'Marker effect',
    },
    397: {
        name: 'Marker effect',
    },
    398: {
        name: 'Marker effect',
    },
    399: {
        name: 'Marker effect',
    },
    400: {
        name: 'Marker effect',
    },
    401: {
        name: 'Marker effect',
    },
    402: {
        name: 'Chain apply',
    },
    403: {
        name: 'Reflect skill',
    },
    404: statIncreasePercent("Critical damage"),
    405: {
        name: 'Deal damage if healed while other effect is applied',
    },
    406: {
        name: 'Apply elemental type',
        async describeTsx(h, effect, value) {
            if (value) {
                const element = Number(value.value);
                let elementStr = '';
                switch (element) {
                    case 1:
                        elementStr = 'Fire';
                        break;
                    case 2:
                        elementStr = 'Ice';
                        break;
                    case 3:
                        elementStr = 'Light';
                        break;
                    case 4:
                        elementStr = 'Dark';
                        break;
                    default:
                        elementStr = `${element}`;
                        break;
                }
                
                return (
                    <span>Convert skills that have no innate element to {elementStr} {durationTsx(h, value.duration)}</span>
                );
            }
            
            return null;
        }
    },
    
    700: {
        name: 'Distance travel check',
        async describeTsx(h, effect, value) {
            if (value) {
                return (
                    <span>Marker state effect for tracking distance traveled {durationTsx(h, value.duration)}</span>
                );
            }
            
            return null;
        },
    },
    701: {
        name: 'Force maximum damage roll',
        async describeTsx(h, effect, value) {
            if (value) {
                return (
                    <span>Forces the max P/MDMG stat to be used for damage calculations instead of randomizing {durationTsx(h, value.duration)}</span>
                );
            }
            
            return null;
        },
    },
    702: {
        name: 'Force reset all cooldowns',
        async describeTsx(h, effect, value) {
            return (
                <span>Forcibly resets the cooldowns of all skills, passives, and crest skills, ignoring cooldown immunity</span>
            );
        },
    },
    703: {
        name: 'Apply multiple state effects',
        async describeTsx(h, effect, value) {
            if (value) {
                const split = value.value.split('$');
                const childEffects = [];
                
                for (const item of split) {
                    const [seIdStr, durationStr, ...paramList] = item.split(';');
                    const stateEffectId = Number(seIdStr);
                    const duration = Number(durationStr);
                    const params = paramList.join(';');
                    
                    let childEffect = await renderChild(h, Number(stateEffectId), params, duration, effect);
                    childEffects.push((<li>{ childEffect }</li>));
                }
                
                return (
                    <div>
                        <span>Apply multiple state effects to the target actor(s) {durationTsx(h, value.duration)}:</span>
                        { childEffects }
                    </div>
                );
            }
            
            return null;
        },
    },
    704: {
        name: 'Crit forgiveness',
        async describeTsx(h, effect, value) {
            if (value) {
                const [abs, ratio] = value.value.split(';').map(Number);
                
                return (
                    <span>Increases damage of non-crits by {filters.percent(ratio)}% + {abs} critical damage {durationTsx(h, value.duration)}</span>
                );
            }
            
            return null;
        },
    },
    705: {
        name: 'Death mark',
        async describeTsx(h, effect, value) {
            if (value) {
            }
            
            return null;
        },
    },
    706: {
        name: 'Guaranteed crit',
        async describeTsx(h, effect, value) {
            if (value) {
                return (
                    <span>Attacks are guaranteed to critically strike regardless of critical chance or critical resistance {durationTsx(h, value.duration)}</span>
                );
            }
            
            return null;
        },
    },
    708: {
        name: 'Final crit resist',
    },
    709: {
        name: 'Unify STR/AGI',
    },
    710: {
        name: 'Apply skill on combo',
    },
    711: {
        name: 'Defer cooldown',
    },
    712: {
        name: 'Highlight skill',
    },
    713: {
        name: 'Camera FoV',
    },
    714: {
        name: 'Hide HP bar',
    },
    715: {
        name: 'Apply skill upon successful iframe',
    },
    716: {
        name: 'Execute bubble',
    },
    717: {
        name: 'Warden marker',
    },
    718: {
        name: 'Detonate stacks',
    },
    719: {
        name: 'Remove specific blows from specific skills',
    },
    720: {
        name: 'Deaths Dance',
    },
    721: {
        name: 'Delayed DoT',
    },
    722: {
        name: 'Universal DoT',
    },
    723: {
        name: 'Vampyra marker',
    },
    724: {
        name: 'Knightess marker',
    },
    725: {
        name: 'Provoke marker',
    },
    726: {
        name: 'True max HP mod',
    },
    727: {
        name: 'Light Fury marker',
    },
    728: {
        name: 'Force show skill cooldown',
    },
    729: {
        name: 'Show crosshair text',
    },
};
